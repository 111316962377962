import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"
import { Link } from "gatsby";
import imgPassenger from '../../images/news/tetleys-coaches-living-wage-employer-accreditation.png'

class NewsPage extends Component {

  componentDidMount() {
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's Coaches Achieves Living Wage Employer Accreditation!" description="Tetley's Coaches Achieves Living Wage Employer Accreditation!" />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's Coaches Achieves Living Wage Employer Accreditation!</h1>
              <p className="news-article-date">April 2024</p>
            </div>

            <img src={imgPassenger} alt="" />

            <div className="news-article-body">
              <p>We are thrilled to announce that Tetley's Coaches has officially been accredited as a Living Wage Employer!</p>
              <p>This achievement reflects our unwavering commitment to fair pay and our dedication to supporting the well-being of our incredible team members. At Tetley's Coaches, we believe that every individual deserves to earn a wage that enables them to live with dignity and security.</p>
              <p>Being accredited as a Living Wage Employer means that we are ensuring our employees receive a fair wage that reflects the real cost of living, regardless of age or job role. It's not just about meeting the minimum requirements; it's about going above and beyond to create a positive and supportive work environment.</p>
              <p>We are immensely proud to join the ranks of Living Wage Employers, standing alongside other organizations that share our values and commitment to social responsibility. This accreditation is a testament to the hard work and dedication of our entire Tetley's Coaches family.</p>
              <p>As we celebrate this milestone, we extend our heartfelt gratitude to our team members for their dedication and contribution to our company's success. Together, we will continue to strive for excellence and uphold the principles of fairness and respect in everything we do.</p>
              <p>Join us in celebrating this significant achievement as we reaffirm our commitment to creating a better, fairer future for all!</p>
              <p>Do you hold a category D licence and have relevant experience driving coaches or buses? Apply for <a href="https://www.tetleyscoaches.co.uk/jobs/">bus and coach driver jobs</a> with Tetley's Coaches.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
